
.paintPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100%;

  background: url('../../assets/images/DeliveryPage/sky.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}
.paintCard {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: fit-content;


    width: 86%;
    margin: 50px auto;
    padding: 20px;
    background: white;
    -moz-box-shadow: 0 0 20px black;
    -webkit-box-shadow: 0 0 20px black;
    box-shadow: 0 0 20px black;
    box-shadow: 0 15px 30px black;
    border-radius: 3%;
}
.paintText {
  padding-top: 1em;
}

.paintImage {
  height: 80%;
  width:80em;
}
