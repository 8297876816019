.sign-up-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 5px auto;
  
  // background-color: #f5f5f5;

  h2 {
    font-size: 2rem;
    margin: 10px 0;
  }

  .buttonContainer {
    margin-left:30px;
    // background-color: #f5f5f5;
  }
}



