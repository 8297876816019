.footer {
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #0EBFE9;

  .options {
    width: 50%;
    height: 90%;
    display: flex;
    align-items: center;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5em;
    padding-right: 5em;
    @media (max-width: 800px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      font-size: large;
    }
  }

  .copyright {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
    font-size: smaller;
  }
  .socialIcon {
    margin: .25em;
    display: flex;
    align-items: flex-end;
  }
  .footerSocial {
    width: 10%;
    display: flex;
    align-items: center;
  }
$link-light-grey: black;
$primary-orange: #fec503;
$footer-background-light-grey: #212529;
$footer-background-grey: #1a1d20;


a {
  color: black;
}
a:hover {
  color: $primary-orange;
  text-decoration: none;
}

::selection {
  background: $primary-orange;
  text-shadow: none;
}

footer {
  padding: 2rem 0;
  background-color: #0EBFE9;
  @media (max-width: 800px) {
    padding: 0;
  }
}

.footer-column:not(:first-child) {
    padding-top: 2rem;
    @media (min-width: 768px) {
      padding-top: 0rem;
    }
  }

.footer-column {
  text-align: center;
  //border: red solid 1px;
  .nav-item {
    .nav-link {
      padding: 0.1rem 0;
    }
    span.nav-link {
      color: $link-light-grey;
    }
    span.footer-title {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
    }
    .fas {
      margin-right: 0.5rem;
    }
  }
  ul {
    display: inline-block;
    @media (min-width: 768px) {
      text-align: left;
    }
    //border: yellow solid 1px;
  }
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: $primary-orange;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  border-radius: 100%;
  outline: 0;
  background-color: $footer-background-grey;
}

footer .quick-links {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

}
