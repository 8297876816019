.faqPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../../assets/images/freebirds.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  justify-content: center;
}

.faqCard {
    width: 90%;
    height: 100%vh;
    margin: 50px auto;
    padding: 10px;
    background: white;
    opacity: .9;
    -moz-box-shadow: 0 0 20px black;
    -webkit-box-shadow: 0 0 20px black;
    box-shadow: 0 0 20px black;
    box-shadow: 0 15px 30px black;
    .faqBody {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      
    }
}
.faqTitle{
  color:white;
  font-size: larger;
}
.faqCardTitle{
  color:black;
  font-size: x-large;
  text-align: center;
  margin: 5px;
  font-weight: bold;
}
.faqCardBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  color:black;
  font-size: large;
  text-align: center;
  margin: 5px;
  font-weight: bold;
}

