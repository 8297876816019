.products-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;
  row-gap: 15px;
  height: 400px;
 

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
.shopPage {
  display: grid;
  margin: 10px;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;

  margin-bottom: 100px;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5px;
    width: 100%;
    height: 90%;
    margin-top: 10;

  }
 
  
}
.shopTitle {

justify-content:center;
  font-size: larger;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
}

.shopBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  color: aquamarine;
  width: 100%;
  height: 100px;
  background-color: #0463f0;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    display: none;
  }
}
