
.shwagPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:80%;
  background: url('../../assets/images/DeliveryPage/sky.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}
.shwagCard {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
    width: 66%;
    margin: 50px auto;
    padding: 20px;
    background: white;
    -moz-box-shadow: 0 0 20px black;
    -webkit-box-shadow: 0 0 20px black;
    box-shadow: 0 0 20px black;
    box-shadow: 0 15px 30px black;
    border-radius: 1%;
}
.shwagText {
  padding-top: 1em;
}
// .bathImages {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   width: 60%;
// }
.shwagImage {
  width: 60%;
  position: center;
  margin: 10px auto;
  border-radius: 50%;
  border: 2px solid black;
  -moz-box-shadow: 0 0 15px black;
  -webkit-box-shadow: 0 0 15px black;
  box-shadow: 0 0 15px black;
  box-shadow: 0 15px 20px black;

}
.cardHeader {
  background:#0EBFE9;
  padding: 5px;
  border-radius: 3%;
  width: 100%;
} 
