.benefitsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../../assets/images/BenefitsPage/beautifulLake.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.benefitsImage {
  height: 100%;
  width:100%;
  border: 2px solid black;
  -moz-box-shadow: 0 0 20px black;
  -webkit-box-shadow: 0 0 20px black;
  box-shadow: 0 0 20px black;
  box-shadow: 0 15px 30px black;
}
.benefitsCard {
  justify-content: center;
  align-items: center;
    width: 88%;
    margin: 50px auto;
    padding: 20px;
    background: white;
    -moz-box-shadow: 0 0 20px black;
    -webkit-box-shadow: 0 0 20px black;
    box-shadow: 0 0 20px black;
    box-shadow: 0 15px 30px black;
    border-radius: 3%;
    z-index: 1;

}
