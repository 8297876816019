
.menu-item {
  width: 90%;
  height: 240px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:6px;
  overflow: hidden;
  padding: 0 20px 0 20px;

  &:hover {
    cursor: pointer;

    & .background-image {
      transform: scale(1.2);
      transition: transform 5s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

    & .content {
      opacity:0.9;
    }
  }

  & .large {
    height: 380px;
  }

  .background-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }

  .content {
    height: 16%;
    width: 28vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    background-color: #FAF0DC;
    opacity: 0.7;
    position: absolute;
  }
    .title {
      font-weight: bold;
      margin-bottom: 2px;
      margin-top: -5px;
      font-size: 22px;
      color: black;
    }

    .subtitle {
      font-weight: lighter;
      font-size: 16px;
      color: black;
    }
}
