
.deliveryPage {
display: flex;
flex-direction: column;
background: url('../../assets/images/DeliveryPage/sky.jpeg');
background-repeat: no-repeat;
background-size: cover;
}
.deliveryCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    width: 90%;
    margin: 3em auto;
    padding: 30px;
    background: white;
    border-radius: 1%;
    -moz-box-shadow: 0 0 13px black;
    -webkit-box-shadow: 0 0 13px black;
    box-shadow: 0 15px 13px black;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 3;
      padding: 3;
    }
}
.deliveryInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
    width: 90%;
    margin: 3em auto;
    padding: 10px;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 3;
      padding: 3;
    }
}
.deliveryText {
  width: 88%;
  padding: 3em;
  font-weight: x-large;
  font-weight: bold;
 
}
.deliveryImage {
  width: 90%;
  border: 2px solid black;
  border-radius: 2%;

}
