.contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../../assets/images/ContactPage/sailBoat2.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.contactImage {
  width: 100%;
  margin-bottom: 1em;
  border-radius: 2%;
}
.contactCard {
  display: flex;
   flex-direction: column;
  justify-content: center;
  align-items: center;
    width: 80%;
    height: 100%  ; 
    margin: 25px auto;
    padding: 5px;
    background: white;
    -moz-box-shadow: 0 0 15px black;
    -webkit-box-shadow: 0 0 15px black;
    box-shadow: 0 0 15px black;
    box-shadow: 0 15px 20px black;
}

.mailerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: 5%;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
}
.contactText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
}
.contactHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 2em;
  font-weight: bold;
  margin-top: 1em;
  cursor: pointer;
}
