.storyPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../assets/images/forestPath.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  justify-content: center;
 

}

.storyCard {
  justify-content: center;
  align-items: center;
    width: 90vw;
    margin: 80px auto;
    padding: 30px;
    background: white;
    opacity: .9;
    -moz-box-shadow: 0 0 20px black;
    -webkit-box-shadow: 0 0 20px black;
    box-shadow: 0 0 20px black;
    box-shadow: 0 15px 30px black;

}
.storyTitle{
  color:white;
  font-size: larger;
  margin: 10px;
}




