.processPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../assets/images/processPage/construction.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.panelCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 95%;
  padding: 1.5em;
  color: white;
  font-size: large;
  margin: 33px auto;
  background: black;
  opacity: .90;
    -moz-box-shadow: 0 0 20px black;
    -webkit-box-shadow: 0 0 20px black;
    box-shadow: 0 0 20px black;
    box-shadow: 0 15px 30px black;
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
 
    }
}
.constructionImgContainer {
  display: flex;
  flex: 30%;
  width: auto;
  height: 300px;
  margin: 5px;
@media (max-width: 800px) {
  flex: none;
  height:auto;
  margin: 2px;

}
}
.constructionImage {
  width: 90%;
  height: 90%;
  object-fit: cover;
}
.processTitle{
  color:black;
  font-size: larger;
}
.processText {
  color:white;
  font-size: large;
  text-align: center;
  margin: 10px;
  font-weight: bold;
}



