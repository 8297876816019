.productDetailsPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: url('../../assets/images/ShopImages/GreenHIlls.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}
.detailIcon {
  margin: 1em;
}
.modelCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 86%;
  margin:1em auto;
  background: white;
  -moz-box-shadow: 0 0 20px black;
  -webkit-box-shadow: 0 0 20px black;
  box-shadow: 0 15px 30px black;
}

.modelImage {
  width:80%;
  margin-bottom: 1em;
  border-top-right-radius: 0;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border: 2px solid black;
  -moz-box-shadow: 0 0 20px black;
  -webkit-box-shadow: 0 0 20px black;
  box-shadow: 0 0 20px black;
  box-shadow: 0 15px 30px black;
  
}

.modelBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  
}
.title {
  font-size: 3em;
  font-weight: bold;
  color: black;
  align-items: center;
}

.modelText {
  margin:2em;
}

@media only screen and (max-width: 768px) {
  a {
    display: none;
  }
  .card-body {
    padding: 0.5em 1.2em;
  }
  .card-body .card-text {
    margin: 0;
  }
  .card img {
    width: 50%;
  }
}
@media only screen and (max-width: 1200px) {
  .card img {
    width: 40%;
  }
}


