
.kitchenPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:80%;
  background: url('../../assets/images/DeliveryPage/sky.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}
.kitchenCard {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
    width: 66%;
    margin: 50px auto;
    padding: 20px;
    background: white;
    -moz-box-shadow: 0 0 20px black;
    -webkit-box-shadow: 0 0 20px black;
    box-shadow: 0 0 20px black;
    box-shadow: 0 15px 30px black;
    border-radius: 1%;
}
.paintText {
  padding-top: 1em;
}

.kitchenImage {
  width: 60%;
  position: center;
  margin: 3rem;
  border: 2px solid black;
  -moz-box-shadow: 0 0 20px black;
  -webkit-box-shadow: 0 0 20px black;
  box-shadow: 0 0 20px black;
  box-shadow: 0 15px 30px black;
  border-radius: 1%;
}
.kitchenFloorPlanImage {
  width: 100%;
  position: center;
  margin: 3rem;
  border: 2px solid black;
  -moz-box-shadow: 0 0 20px black;
  -webkit-box-shadow: 0 0 20px black;
  box-shadow: 0 0 20px black;
  box-shadow: 0 15px 30px black;
  border-radius: 1%;
}

.cardHeader {

  background:#0EBFE9;
  padding: 1px;
  width: 100%;
} 
