

.product-card-container {
  width: 100%;
  height:400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 2px solid black;
  border-radius: 5%;
  background-color: #0EBFE9;
  @media (max-width: 800px) {
    width: 100vw;
    height: auto;
    border: none;
    margin: 0 auto;
  }

  img {
    width: 22vw;
    height: 90%;
    align-items: center;
    position: relative;
    border-radius: 5% 5% 0 0;
    @media (max-width: 800px) {
      width: 100%;
      height: 100vw;
      border: none;
    }
  }

  button {
    width: 80%;
    opacity: 0.7;
    position: absolute;
    top: 255px;
    display: none;
 
    @media (max-width: 800px) {
      width: 40vw;
      height: auto;
      background-color: black;
      color: white;
      font-size: small;
      border: none;
      opacity: 1;
      cursor: pointer;
      display: flex;
    }
  }

  &:hover {
    img {
      opacity: 0.8;
    }
    button {
      opacity: 0.85;
      display: flex;
    }
  }

  .productFooter {
    width: 100%;
    height :10%;
    opacity: .9;
    font-size: 18px;
    border-radius: 0 0 5% 5%;
    padding:2px;
  
    @media (max-width: 800px) {
     font-size: small;
     height: 8%
    }

    .name {
      width: 90%;
      margin-bottom: 15px;
      padding: 5px;
    }

    .price {
      width: 60%;
    }
  }
  .customButton {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    opacity: .9;
    top: -13em;
  }
}
