.detailsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/images/DesertPhoto.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.blueprintCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 5em;
  width: 60%;
  border: 2px solid black;
}

.detailsTitle {
    font-size: 2.5rem;
    font-weight: bold;
    color: black;
    text-align: center;
    margin: 1rem;
}
.detailIcon {
  margin: 1em;
}
.blueprintImage {
  width: 80%;

}
.bodyParagraph {
  padding: 5;
  margin: 5;
}
.detailsCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
    width: 90%;
    margin: 50px auto;
    padding: 20px;
    -moz-box-shadow: 0 0 20px black;
    -webkit-box-shadow: 0 0 20px black;
    box-shadow: 0 0 20px black;
    box-shadow: 0 15px 30px black;
    z-index: 1;
}
.specList{
  font-weight: bold;
  margin-top: 3px;
}

